<template>
    <div class="container pd100 mb20 appointment_schedule">
        <vue-hash-calendar 
                    ref="calendar"
                    weekStart='monday'
                    @click="handleClick"
                    @change="handleChange"
                    @slidechange="handleSlidechange"
                    checkedDayClassName="schedule-calendar-color"
                    format="YY-MM-DD"
                    :defaultDatetime="defaultDatetime"
                    :visible="true" 
                    :isShowArrow="false" 
                    :isShowAction="false" 
                    :scrollChangeDate="true"
                    :markDate="markDate"
                    :isShowWeekView="isShowWeekView">
        </vue-hash-calendar>
        <div class="campus_schedule_search">
            <van-cell-group>
                <van-cell @click="showDropStudio = true" title="校区" is-link :value="studioValue.studio_name" />
                <van-cell @click="showTeacher = true" title="老师" is-link :value="teacherValue.filter_name" />
                <van-cell title="时间">
                    <van-checkbox-group checked-color="#00cca2" @change="handleCheckbox" v-model="checkboxGroup" direction="horizontal">
                        <van-checkbox name="forenoon" shape="square" icon-size="18">上午</van-checkbox>
                        <van-checkbox name="afternoon" shape="square" icon-size="18">下午</van-checkbox>
                        <van-checkbox name="evening" shape="square" icon-size="18">晚上</van-checkbox>
                    </van-checkbox-group>
                </van-cell>
            </van-cell-group>
        </div>
        <div style="margin-bottom:45px;">
            <div v-for="(it,ind) in courses" :label="`${it.course_name}`" :key="ind" class="appointment_schedule_item" @click="course_book(it)">
                <van-cell-group @click="toPath(it.course_class_id,it)" :border="false">
                    <van-cell :value="it.teacher" style="background: linear-gradient(to right,#fff 35%,#22de9e73);" :border="true">
                        <template #title>
                            <div>
                                <van-image
                                round
                                width="0.8rem"
                                height="0.8rem"
                                fit="cover"
                                :src="it.teacher_avatar"
                                style="float: left;margin-right: 5px;"
                                />
                                <div style="font-weight: bold;">
                                    {{`${it.class_time}`}}
                                    <span style="font-size: 12px;" v-if="(it.course_price > 0)">{{`${it.course_costs}`}}课时/{{`${it.course_price}`}}元</span>
                                    <span style="font-size: 12px;" v-else>{{`${it.course_costs}`}}课时</span>
                                </div>
                                <div style="font-size: 12px;">
                                    {{`${it.course_name}`}}
                                </div>
                            </div>
                        </template>
                        <template #label>
                            <van-tag plain type="success" v-if="it.reserve_count<5">{{it.reserve_count}}/{{it.table_count}}</van-tag>
                            <van-tag plain type="warning" v-if="it.reserve_count>=5 && it.reserve_count<8">{{it.reserve_count}}/{{it.table_count}}</van-tag>
                            <van-tag plain type="danger" v-if="it.reserve_count>=8">{{it.reserve_count}}/{{it.table_count}}</van-tag>
                            <van-tag plain type="success" style="margin-left: 5px;display: inline-block;word-break: keep-all;overflow: hidden;text-overflow: ellipsis;" v-for="(label_name,index) in it.course_label" :key="index">{{label_name}}</van-tag>
                            <van-tag plain type="success" style="margin-left: 5px;display: inline-block;word-break: keep-all;overflow: hidden;text-overflow: ellipsis;">{{it.room_name}}</van-tag>
                        </template>
                    </van-cell>
                </van-cell-group>

                <van-tag type="success" v-if="it.course_can_book && it.reserve_count<it.table_count" class="appointment_schedule_item_btn">去约课</van-tag>
                <van-tag type="warning" v-if="it.course_can_book && it.reserve_count >=it.table_count" class="appointment_schedule_item_btn">去等位</van-tag>
            </div>
        </div>
        <van-empty v-if="courses.length == 0 && !loading" description="暂无相关数据~" />
        <van-popup v-model="showStudio" position="bottom" round>
            <van-picker
                show-toolbar
                value-key="studio_name"
                :columns="studios"
                @confirm="onConfirmStudio"
                @cancel="showStudio = false"
            />
        </van-popup>
        <van-popup v-model="showTeacher" :style="{ height: '65%' }" position="bottom" round>
            <van-picker
                show-toolbar
                :columns="teachers"
                value-key="filter_name"
                @cancel="showTeacher = false"
                @confirm="onConfirmTeacher"
            />
        </van-popup>
         <van-popup v-model="showApply" position="bottom" :style="{ height: '85%' }" closeable close-icon="close" round >
            <Apply :course_class_id="course_class_id" :detail="detail"/>
        </van-popup>
        <van-popup v-model="showDropStudio" position="bottom" :style="{ height: '80%' }" round >
            <van-tree-select
                height="154.5vw"
                :items="areaList"
                :active-id.sync="areaActiveId"
                :main-active-index.sync="areaActiveIndex"
                @click-nav="onClickNav"
                @click-item="onAreaClicked"
            />
        </van-popup>
        <!-- 底部导航栏 -->
        <copyright />
        <Tabbar v-show='!loading' />
        <loadings :loading='loading' />
    </div>
</template>

<script>
    import { Grid, GridItem, ActionSheet, Dialog, Tag, Picker, Empty, NoticeBar, Toast, TreeSelect, Checkbox, CheckboxGroup,Sticky ,Tab, Tabs,Image ,Progress} from 'vant'
    import dayjs from 'dayjs'
    import Loadings from '@/components/loading'
    import Tabbar from '@/components/Tabbar'
    import Copyright from '@/components/Copyright'
    import Apply from './apply'
    export default {
        name:"appointment",
        data() {
            return {
                studios: [],
                studio_id:'',
                studioValue:{},
                areaList:[],
                areaActiveIndex:'',
                areaActiveId:'',
                schedule_can_book:true,
                showStudio:false,
                showApply:false,
                showDropStudio:false,
                teachers: [],
                teacher_id:[],
                course_class_id:null,
                teacherValue:{},
                showTeacher:false,
                defaultDatetime:new Date(dayjs().year(), dayjs().month(), dayjs().date()),
                active:{date:dayjs().format('YYYY-MM-DD')},
                times:dayjs().format('YYYY-MM-DD'),
                courses:[],
                showAge:false,
                age_id:'',
                activeTab:0,
                activeBar:"-1",
                isRefresh:0,
                firstIn:1,
                latitude:'31.31669044494629',
                longitude:'121.40471649169922',
                isShowWeekView:true,
                isShowWeekViews:true,
                // minDate: '',
                // maxDate: '',
                minDate: dayjs().toDate(),
                maxDate: dayjs().toDate(),
                markDate:[],
                appointmentClose:'',
                checkboxLevelGroup:[],
                checkboxGroup:[],
                forenoon:1,
                afternoon:1,
                evening:1,
                detail:{},
                book_id:this.$route.query.book_id
            }
        },
        components: {
            Copyright,
            [Image.name]:Image,
            [Dialog.name]:Dialog,
            [Sticky.name]:Sticky,
            [Toast.name]: Toast,
            [Checkbox.name]:Checkbox,
            [CheckboxGroup.name]:CheckboxGroup,
            [Tag.name]:Tag,
            [Tab.name]:Tab,
            [Progress.name]:Progress,
            [Tabs.name]:Tabs,
            [TreeSelect.name]:TreeSelect,
            [Empty.name]: Empty,
            [Picker.name]:Picker,
            [NoticeBar.name]:NoticeBar,
            loadings:Loadings,
            Tabbar:Tabbar,
            Apply:Apply
        },
        computed: {
        },
        created () {
            if(dayjs().hour()>=20){
                this.defaultDatetime = new Date(dayjs().year(), dayjs().month(), dayjs().date()+1)
                this.times = dayjs().add('1','day').format('YYYY-MM-DD')
            }
            this.appointmentClose = localStorage.getItem('appointmentClose',1)
            this.loading = true
            this.getLocation()
            this.getStudio()
        },
        activated() {
            this.getCourses()
        },
        methods: {
            dayjs,
            handleClick(name,num){
                this[name] = true
            },
            handleCheckbox(e){
                this.getCourses()
            },
            onScheduleChange(value) {
                this.times = value.date
                this.getCourses()
            },
            onClickNav(data){},
            onAreaClicked(data) {                
                this.studio_id = data.id;
                this.studioValue = {
                    studio_name:data.text,
                    studio_id:data.id,
                }
                this.showDropStudio=false
                this.getTime(this.studio_id)
            },
            course_book(item){
                if(!item.course_can_book){
                    Toast('【训练班】选课展示,无法预约。');
                }
            },
            getTime(studio_id){
                this.firstIn++
                this.getMarkDate(studio_id)
                this.getTeacher()
                this.getCourses()
            },
            getStudio(){
                this.$api.custom_available_studios({latitude:this.latitude,longitude:this.longitude,studio_id:this.$route.query.studio_id})
                .then(res=>{
                    this.studios = res.data
                    this.areaList = res.studio_district
                    this.showDropStudio = false
                    if(res.current_studio){
                        this.studioValue = res.current_studio
                        this.studio_id = res.current_studio.studio_id
                        this.getTime(this.studio_id)
                        // console.log(this.studioValue)
                    }else{
                        if(this.studios.length>0){
                            this.studioValue = this.studios[0]
                            this.studio_id = this.studioValue.studio_id
                            this.getTime(this.studio_id)
                        }
                    }
                    
                })
            },
            getMarkDate(studio_id){
                this.$api.custom_available_schedule_date({studio_id:studio_id})
                .then(res=>{

                    let markDate = []
                    res.mark_date.forEach(item=>{
                        markDate.push(dayjs(item).format('YYYY/MM/DD'))
                    })
                    this.markDate = [{color: '#ecbe4b',date: markDate}]

                })
            },
            getTeacher(){
                this.$api.custom_campus_teacher({studio_id:this.studio_id})
                .then(res=>{
                    this.teachers = res.data
                    this.teachers.unshift({filter_name:'全部',teacher_id:''})
                    this.teacherValue = {filter_name:'全部',teacher_id:''}
                })
            },
            getCourses(){
                this.loading = true
                let obj = {
                    studio_id:this.studio_id,
                    teacher_id:this.teacher_id,
                    start_date:this.times,
                    end_date:this.times,
                    age_id:this.age_id,
                    level_range:this.checkboxLevelGroup,
                    time_range:this.checkboxGroup
                }
                this.$api.custom_campus_schedule(obj)
                .then(res=>{
                    this.courses = res.data.schedule_list
                    this.schedule_can_book = res.data.schedule_can_book
                    // let markDate = []
                    // this.courses.forEach(item=>{
                    //     markDate.push(dayjs(item.date).format('YYYY/MM/DD'))
                    // })
                    // this.markDate = [{color: '#ecbe4b',date: markDate}]
                    this.loading = false
                })
            },
            getLocation(){
                wx.ready(function () {
                    wx.getLocation({
                    type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
                    success: function (res) {
                        // res 中返回经度和纬度，可以根据需求是否转换为QQ地图的精度
                        this.latitude = res.latitude
                        this.longitude = res.longitude
                        // alert(JSON.stringify(res));
                    },
                    cancel: function (res) {
                        // console.log('用户拒绝授权获取地理位置');
                    },
                    fail: function (res) {
                        // alert(JSON.stringify(res));
                    }
                });
                });
            }, 
            onConfirmStudio(value){
                this.studioValue = value
                this.studio_id = value.studio_id
                this.getTime(this.studio_id)
                this.showStudio = false
            },
            onConfirmTeacher(value){
                this.teacherValue = value
                this.teacher_id = value.teacher_id
                this.getCourses()
                this.showTeacher = false
            },
            onConfirmAge(value){
                this.ageValue = value
                this.age_id = value.value
                this.getCourses()
                this.showAge = false
            },
            getDetail() {
            },
            toPath(course_class_id,it){
                var that = this
                if(this.schedule_can_book <= 0){
                    Dialog.confirm({
                        message: '去购买课程',
                        })
                        .then(() => {
                            // on confirm
                            // premium/detail?fee_id=11339&studio_id=121
                            if(it.course_single_fee_id){
                                const params = {
                                    fee_id: it.course_single_fee_id,
                                    studio_id: that.studio_id,
                                    course_class_id:it.course_class_id
                                }
                                that.$toast.loading({
                                    mask: true,
                                    message: '提交中...',
                                    duration: 0
                                })
                                that.$api.payment_contract(params).then(res => {
                                    that.$toast.clear()
                                    that.$router.push({path:'/orderCenter',query:{out_trade_no:res.data.out_trade_no}})
                                }).catch(err => {
                                    this.$toast.fail('课程购买失败')
                                    console.error(err)
                                })
                                // this.$router.push({path:'/premium/detail',query:{studio_id:that.studio_id,fee_id:it.course_single_fee_id}})
                            }else{
                                this.$router.push({path:'/premium',query:{studio_id:that.studio_id}})
                            }
                        })
                        .catch(() => {
                            // on cancel
                        });
                        return false;
                }
                this.course_class_id=course_class_id;
                this.loading=true;
                this.$api.custom_campus_schedule_detail({course_class_id:course_class_id}).then(res=>{
                    this.detail = res.data
                    this.showApply=true;
                    this.loading=false;
                })
            },
            handleClick(e){},
            handleChange(e){
                if(this.firstIn === 1) return false
                this.active = e
                this.times = e
                this.getCourses()
            },
            handleSlidechange(e){},
            isShowWeeks(){
                this.isShowWeekViews = !this.isShowWeekViews
                localStorage.setItem('isShowWeekViews',this.isShowWeekViews)
            },
            handleClose(){
                localStorage.setItem('appointmentClose',1)
            }
        },
        beforeRouteLeave(to, from, next) {
            if (to.path === '/huidong/appointment-apply') { // 去往详情页的时候需要缓存组件，其他情况下不需要缓存
                this.$store.commit('app/updateCatchComponents', ['appointment'])
            } else {
                this.$store.commit('app/updateCatchComponents', [])
            }
            next()
        },
    }
</script>

<style lang="less" scoped>
    .section{
        background: #fff;
        &-row{
        padding: 10px 10px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        }
        &-studio{
        box-sizing: border-box;
        width: 110px;
        height: 30px;
        border-radius: 4px;
        padding: 4px 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #666;
        font-size: 14px;
        border: 1px solid #eee;
        }
        &-teacher{
        margin-left: 5px;
        box-sizing: border-box;
        width: 110px;
        height: 30px;
        border-radius: 4px;
        padding: 4px 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #666;
        font-size: 14px;
        border: 1px solid #eee;
        }
        &-age{
        margin-left: 5px;
        box-sizing: border-box;
        width: 110px;
        height: 30px;
        border-radius: 4px;
        padding: 4px 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #666;
        font-size: 14px;
        border: 1px solid #eee;
        }
    }
    .appointment_schedule_item{
position: relative;
&_btn{
    // width: 50px;
    position: absolute;
    bottom: 8px;
    right: 15px;
    font-size: 12px;
}
&_mark{
    position: absolute;
    top: 0;
    left: 0;
    img{
        width: 40px;
    }
    &_text{
        width: 28px;
        transform: rotate(-45deg);
        position: absolute;
        top: 7px;
        left: 1px;
        color: #fff;
        font-size: 10px;
        text-align: center;
    }
    &_textF{
        width: 30px;
        transform: rotate(-45deg);
        position: absolute;
        top: 7px;
        color: #fff;
        font-size: 10px;
        text-align: center;
    }
}
.custom-title {
    width: 240px;
    display: block;
}
.van-cell{
    position: relative;
    padding: 8px;
}
.van-cell-group{
    width: 96%;
    margin: 0 auto;
    border-radius: 10px;
    overflow: hidden;
    margin-top: 10px;
}
.van-cell__title{
    flex: 4;
}
.van-cell__label{
    display: flex;
    font-size: 11px;
    align-items: baseline;
}
}
.campus_schedule_search{
    .van-cell__value{
        flex: 2;
        min-width: 80%;
        span {
            display: inline-block;
            text-align: left;
            word-break: break-all;
        }
    }
    .van-checkbox--horizontal{
        margin-right: 0;
        margin-left: 12px;
    }
    .van-checkbox-group--horizontal{
        justify-content: flex-end;
    }
}
.van-cell-group{
    width: 95%;
    margin: 0 auto;
    border-radius: 10px;
    overflow: hidden;
    margin-top: 10px;
}
.campus_schedule_item{
    .van-cell__title{
        flex: 3;
    }
    .van-cell__label{
        display: flex;
        align-items: center;
    }
    &-img{
        width: 20px;
        position: absolute;
        right: 15px;
    }
}

@height:80px;
.schedule-list-item{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
    background: #fff;
    padding-left: 10px;
    position: relative;
    &-hd{
      position: relative;
      box-sizing: border-box;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      margin-right: 10px;
      height: @height;
      padding-left: 20px;
      padding-top: 8px;
    }
    &-dot{
      position: absolute;
      top: 14px;
      left: 0;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      overflow: hidden;
      background: #00DE93;
      margin-right: 20px;
    }
    &-time{
      font-weight: bold;
      font-size: 16px;
      &-title{
        // margin-bottom: 5px;
      }
      &-txt{
        font-size: 12px;
        font-weight: normal;
      }
    }
    &-title{
      font-size: 14px;
      color: #333;
      margin-bottom: 5px;
    }
    &-teacher{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 10px;
      color: #666;
      margin-bottom: 5px;
      &-img{
        width: 16px;
        height: 16px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 5px;
      }
    }
    &-bd{
      box-sizing: border-box;
      // height: @height;
      padding: 10px 15px;
      // background: #f6f9fc;
      border-radius: 4px;
      flex: 1;
      font-size: 10px;
      color: #666;
      min-width: 0;
    }
    .gray{
      color: #b2b2b2;
    }
    .orange{
      color: #ff993e;
    }
    &-chat{
      position: absolute;
      top: 30px;
      right: 10px;
    }
  }
  .list{
    padding: 0 15px;
  }
  .list-item{
    box-sizing: border-box;
    width: 100%;
    padding: 10px 0;
    padding-left: 10px;
    padding-right: 10px;
    background: #fff;
    margin-bottom: 10px;
    // border-radius: 8px;
    display: flex;
    &-media{
      margin-right: 10px;
      &-img{
        width: 60px;
        height: 60px;
        border-radius: 10%;
      }
    }
    &-inner{
      flex: 1;
      margin-right: 10px;
    }
    &-title{
      font-size: 16px;
      color: #333;
      margin-bottom: 5px;
    }
    &-info{
      min-height: 40px;
      font-size: 14px;
      color: #666;
    }
    &-txt{
      font-size: 14px;
      color: #999;
    }
    &-right{
      display: flex;
      align-items: center;
    }
  }
</style>
<style lang="less">
.appointment_schedule{
    .calendar_content{
        overflow: initial;
    }
    .calendar_day{
      font-size:3.33333333333334vw;
    }
    .schedule-calendar-color{
        background:#00cca2;
        color:#fff !important;
        // border-radius: 15%;
    }
    .calendar_day_today{
        // border-radius: 15%;
    }
    .calendar_first_today{
        color:#00cca2;
    }
}
</style>